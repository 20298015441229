// import { Link } from "gatsby"
import React, { useEffect } from "react"
// import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
// import "./../../assets/sass/components/service/facebook-page-plugin.scss"

import { FacebookProvider, Page } from 'react-facebook';


/**
 * @see: https://developers.facebook.com/docs/plugins/page-plugin
 * @param {*} props 
 */
const FacebookBadge = (props) => {

    const { children, intl, serviceId, serviceName } = props

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    // useEffect(() => {
    //     loadScripts(props);
    // }, []);
    // const loadScripts = async (props) => {
    // }

    return (
        <>
            {/* <div id="fb-root"></div>
            <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v3.3"></script>
            <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.3"></script> */}
            {/* <div className="fb-page" data-href={ serviceId } data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite={ serviceId } className="fb-xfbml-parse-ignore"><a href={ serviceId }>{ serviceName }</a></blockquote></div> */}
            {/* <div className="fb-page" data-href="https://www.facebook.com/HeuresisPL/" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/HeuresisPL/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/HeuresisPL/">Heuresis - Odkryj głębię swoich możliwości</a></blockquote></div> */}

            <div id="face-slider" className="slide-in-right">
                <div id="tab" className="handler">
                    {/* <span>Facebook</span> */}
                </div>
                <div id="face-code" className="container">
                    <FacebookProvider 
                        appId="2931864513754381" 
                        lazy // @see: https://github.com/seeden/react-facebook/issues/175
                    >
                        <Page href="https://www.facebook.com/HeuresisPL/" tabs="timeline" />
                    </FacebookProvider>   
                </div>
            </div>
        </>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default injectIntl(FacebookBadge)
